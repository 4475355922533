import styled from 'styled-components'
import { Link } from 'react-router-dom'

import ContentColumns from '../components/ContentColumns'
import ContentItem from '../components/ContentItem'
import Paragraph from '../components/Paragraph'
import Page from '../components/Page'
import ExternalLink from '../components/ExternalLink'
import { bookAndFun, bookAndFunMap, feministBookshop, feministBookshopMap, goshComics, goshMap, onlineShopUS, onlineShopWorld } from '../content/externalLinks'
import { getPage } from '../Routes'

const Title = styled.h1`
  margin: .5em 0;
  font-size: 1.8rem;
`

function WhereToBuy () {
  return (
    <Page>
      <ContentItem>
        <Title>Where to buy</Title>

        <Paragraph>We are slowly expanding the online and physical shops where we sell our zines. If you have any suggestions or want to have them in your own shop, <Link to={getPage('contact').path()}> please let us know</Link>!</Paragraph>
      </ContentItem>
      <ContentColumns>
        <div>
          <ContentItem>
            <h2>Online</h2>
            <Paragraph $large>Our <strong>official online seller</strong> is the lovely <ExternalLink href={onlineShopWorld}>Mikan General Store</ExternalLink>. The zines are printed and shipped with care from the United Kingdom.</Paragraph>
            <Paragraph $large>For the <strong>United States and Canada</strong>, we have discounted prices in <ExternalLink href={onlineShopUS}>the usual spot</ExternalLink>. They are printed and shipped by one of our team members, Michael Green.</Paragraph>

            <Paragraph>Want to sell our zines too? <Link to={getPage('contact').path()}> Let us know</Link>!</Paragraph>
          </ContentItem>
        </div>

        <div>
          <ContentItem>
            <h2>In person</h2>
            <Paragraph $large>You can find some of our zines in <strong>London, England, United Kingdom</strong> at <ExternalLink href={goshComics}>Gosh</ExternalLink>. <ExternalLink href={goshMap}>View map</ExternalLink></Paragraph>
            <Paragraph $large>In <strong>Brighton, England, United Kingdom</strong> at <ExternalLink href={feministBookshop}>The Feminist Bookshop</ExternalLink>. <ExternalLink href={feministBookshopMap}>View map</ExternalLink></Paragraph>
            <Paragraph $large>And in <strong>Guangzhou, Guangdong Province, China</strong> at the <ExternalLink href={bookAndFun}>Bookandfun bookstore</ExternalLink>. <ExternalLink href={bookAndFunMap}>View map</ExternalLink></Paragraph>

            <Paragraph>Want to have us in your own shop? <Link to={getPage('contact').path()}> Let us know</Link>!</Paragraph>
          </ContentItem>
        </div>
      </ContentColumns>
    </Page>
  )
}

export default WhereToBuy
