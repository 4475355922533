import {Link} from 'react-router-dom'

import ContentItem from '../ContentItem'
import ContentLink from '../ContentLink'
import SpreadImage from '../SpreadImage'

function ViewTheGallery () {
  return (
    <ContentItem hasPadding={false}>
      <Link to="/gallery">
        <SpreadImage src="/images/gallery/cover-zine2-grass.jpg" />
      </Link>

      <ContentLink to="/gallery" highlighted>
        Gallery
      </ContentLink>
    </ContentItem>
  )
}

export default ViewTheGallery
