import styled from 'styled-components'

import {emphasis} from '../styles/fonts'

const Emphasis = styled.span`
  font-family: ${emphasis};
  line-height: 1.2;
`

export default Emphasis;
