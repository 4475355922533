import {ReactNode} from 'react'

export type ContentParagraph = {
  text: ReactNode,
  emphasis?: boolean
}

export type ContentParagraphs = ContentParagraph[]

export enum ZineId {
  ZINE_1 = 'ZINE_1',
  ZINE_2 = 'ZINE_2',
  ZINE_3 = 'ZINE_3',
  ZINE_4 = 'ZINE_4'
}

export type Zine ={
  id: ZineId,
  title: string,
  description?: string,
  link?: string
}

type ArtistAttributes = {
  name: string,
  country?: string,
  twitter?: string,
  instagram?: string,
  website?: string,
  featuredIn?: ZineId[]
}

type ArtistDescriptionAttributes = {
  description?: ReactNode
} | ArtistAttributes & {
  quote?: ReactNode
}

export type Artist = ArtistAttributes & ArtistDescriptionAttributes & {
  image: string
}

export type GuestArtist = ArtistAttributes & ArtistDescriptionAttributes

type ReviewAuthor = {
  name: string,
  description: string,
  link: string
}

export type Review = {
  content: ContentParagraphs,
  author: ReviewAuthor
}
