import styled from 'styled-components'

import ContentColumns from '../components/ContentColumns'
import ContentItem from '../components/ContentItem'

import Page from '../components/Page'
import Paragraph from '../components/Paragraph'
import ExternalLink from '../components/ExternalLink'

import { ZineId } from '../content/types'
import { zines } from '../content/zines'

import { awardColours } from '../styles/colors'

const PageTitle = styled.h1`
  margin: 1em 0;
  font-size: 1.8rem;
  color: ${awardColours.firstPrize};
`

function Zine ({zineId}: {zineId: ZineId}) {
  const zine = zines.find(z => z.id === zineId)

  if (!zine) return null;

  return (
    <ContentItem>
      <h2>{zine.title}</h2>

      <Paragraph $large>{zine.description}</Paragraph>

      <Paragraph $large>
        {zine.link ? <ExternalLink href={zine.link}>Buy now</ExternalLink> : 'Coming soon!'}
      </Paragraph>
    </ContentItem>
  )
}

function OurZines () {
  return (
    <Page>
      <ContentItem>
        <PageTitle>Our Zines</PageTitle>

        <Paragraph $large>
          It is our ambition to promote new talents and voices in the world of comics and beyond. Besides members of the core Distant Connections group, our zines feature work from guest artists from all over the world.
        </Paragraph>
      </ContentItem>

      <ContentColumns>
        <div>
          <Zine zineId={ZineId.ZINE_4} />
          <Zine zineId={ZineId.ZINE_3} />
        </div>

        <div>
          <Zine zineId={ZineId.ZINE_2} />
          <Zine zineId={ZineId.ZINE_1} />
        </div>
      </ContentColumns>
    </Page>
  )
}

export default OurZines
