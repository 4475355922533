import { ReactNode } from "react"
import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

import {linkStyles, highlightedLink} from '../styles/emphasisLinks'
import { secondary } from "../styles/colors"

interface Props {
  children: ReactNode
  to: string
  highlighted?: boolean
}

const common = css`
  position: absolute;
  bottom: -0.2em;
  right: -0.3em;
`

const External = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer'
})<{$highlighted: boolean}>`
  ${linkStyles}
  ${common}
  ${props => props.$highlighted ? highlightedLink(secondary) : ''}
`

const Internal = styled(Link)<{$highlighted: boolean}>`
  ${linkStyles}
  ${common}
  ${props => props.$highlighted ? highlightedLink(secondary) : ''}
`

function ContentLink ({children, to, highlighted = false}: Props) {
  const isExternal = to.startsWith('http')

  return isExternal ? (
    <External href={to} $highlighted={highlighted}>
      {children}
    </External>
  ) : (
    <Internal to={to} $highlighted={highlighted}>
      {children}
    </Internal>
  )
}

export default ContentLink
