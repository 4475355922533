import {
  BrowserRouter as Router
} from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import styled from 'styled-components'

import Header from './components/Header'
import Navigation from './components/Navigation'
import Footer from './components/Footer'

import {lg, sm} from './styles/spacing'
import { fromMedium } from './styles/mediaQueries'

import Routes from './Routes'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1300px;
  margin: auto;
  min-height: 100vh;
  padding: ${sm}px;

  ${fromMedium} {
    padding: ${lg}px;
  }
`

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  main {
    flex: 1 1 auto;
    z-index: 100;
  }

  ${fromMedium} {
    flex-direction: row;
  }
`

const queryClient = new QueryClient()

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          <Layout>
            <Header />
            <ContentArea>
              <Navigation />
              <Routes />
            </ContentArea>
            <Footer />
          </Layout>

          <div id="modal" />
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
