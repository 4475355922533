export const primary = '#302973'
export const primaryLight = '#504D93'
export const primaryDark = '#0C0A3A'
export const secondary = '#E7823C'
export const tertiary = '#553B67'
export const light  = '#FFFFFF'
export const dark = '#000000'
export const success = '#058728'
export const error = '#871605'

export const awardColours = {
  firstPrize: '#CD3F81',
  specialJuryPrize: '#E5772E',
  cassArtsAward: '#7DA50E',
  specialMention: '#0D6793',
  audienceAward: '#6A38B2',
}
