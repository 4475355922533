import styled from 'styled-components'
import {Link} from 'react-router-dom'

import { getPage } from '../Routes'
import { navigationSize, layoutMargin } from '../styles/config'

import logo from '../images/layout/logo-orange.png'
import logo15 from '../images/layout/logo-orange@1.5x.png'
import logo20 from '../images/layout/logo-orange@2x.png'

import banner from '../images/layout/banner.png'
import banner15 from '../images/layout/banner@1.5x.png'
import banner20 from '../images/layout/banner@2x.png'
import { fromDesktop, fromMedium } from '../styles/mediaQueries'

const Container = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${layoutMargin};
`

const Logo = styled(Link).attrs({
  to: getPage('home').path(),
  title: 'Distant Connections homepage'
})`
  display: flex;
  width: ${navigationSize};
  margin-right: ${layoutMargin};
  flex: 0 0 ${navigationSize};

  > img {
    width: 239px;
  }
`

const Banner = styled.div`
  overflow: hidden;
  flex: 1 1 auto;
  display: none;

  ${fromMedium} {
    display: block;

    > img {
      height: 100px;
    }
  }

  ${fromDesktop} {
    > img {
      height: auto;
    }
  }
`

export default function Header () {
  return (
    <Container>
      <Logo>
        <img
          srcSet={`${logo}, ${logo15} 1.5x, ${logo20} 2x`}
          src={logo}
          alt="Distant Connections"
        />
      </Logo>

      <Banner>
        <img
          srcSet={`${banner}, ${banner15} 1.5x, ${banner20} 2x`}
          src={banner}
          alt=""
        />
      </Banner>
    </Container>
  )
}
