import styled from 'styled-components'

import { secondary } from '../styles/colors';
import {emphasis} from '../styles/fonts'

const Quote = styled.span<{$type: 'open' | 'close'}>`
  font-family: ${emphasis};
  font-size: 1.5em;
  color: ${secondary};
  display: inline-block;
  vertical-align: ${props => props.$type === 'open' ? 'text-bottom' : 'text-top' };
  padding-left: ${props => props.$type === 'open' ? '0' : '.2em' };
  padding-right: ${props => props.$type === 'open' ? '.2em' : '0' };

  &::before {
    content: "${props => props.$type === 'open' ? '“' : '”' }";
    display: inline;
  }
`

export default Quote;
