import styled from 'styled-components'

import { light } from '../styles/colors'

const Container = styled.footer`
  color: ${light};
  margin-top: 2em;
`

function Footer () {
  return (
    <Container id="footer">Copyright 2021 @ Distant Connections</Container>
  )
}

export default Footer
