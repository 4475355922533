import { Zine, ZineId } from './types';

export const zines: Zine[] = [{
  id: ZineId.ZINE_1,
  title: 'The Lockdown Lowdown #1',
  link: 'https://www.etsy.com/uk/listing/1173874093/the-lockdown-lowdown-zine-graphic',
  description: "The Lockdown Lowdown is a publication about possibilities within this new, abnormal world of restrictions. It is a rich harvest of stories, filled with surreal drawing, the thread of time, pathos, transforming penguins, humour, anxious denial, luscious exuberance, a talking fox, biting snakes, popping eyeballs, political consciousness, bra freedom and disillusioned spoke wheels."
}, {
  id: ZineId.ZINE_2,
  title: 'The Lockdown Lowdown #2',
  link: 'https://www.etsy.com/uk/listing/1159926180/the-lockdown-lowdown-zine-graphic',
  description: "For the Lockdown Lowdown 2, we tried to focus on positive outcomes of the lockdown and explore how the pandemic has in some cases helped people connect in different ways. Well, it\'s not always easy to see the up side, but we tried! For this issue, we are thrilled to have some special guests from the \"Graphic Medicine\" community."
}, {
  id: ZineId.ZINE_3,
  title: 'Women and Covid: a Gendered Pandemic',
  link: 'https://www.etsy.com/uk/listing/1159932148/the-lockdown-lowdown-zine-women-and',
  description: "The COVID-19 pandemic had and will continue to have a different impact on women and men. This was surprising to many. But should it hav been a surprise? When it comes to gender, presumably this pandemic is no different from previous pandemics. To celebrate International Women's Day 2021, Distant Connections sought submissions to a Zine exploring the differential impact the Covid-19 pandemic has had on women."
}, {
  id: ZineId.ZINE_4,
  title: 'Finding Our Future',
  // link: 'https://www.etsy.com/uk/listing/1159932148/the-lockdown-lowdown-zine-women-and'
  description: "The theme for our new zine #4 emerged from our discussions about world events as we navigated our way out of a global pandemic. We felt we were transitioning into a society that was rapidly changing, an experience that alternated between feeling brave and precarious. From the #MeToo movement to the conflict in Ukraine, and the persistent gender disparity in workplaces and homes, it became clear that there was an important conversation to explore within this zine."
}]
