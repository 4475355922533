import styled from 'styled-components'

import ContentColumns from '../components/ContentColumns'
import ContentItem from '../components/ContentItem'

import Page from '../components/Page'
import Paragraph from '../components/Paragraph'
import ExternalLink from '../components/ExternalLink'

import { ZineId } from '../content/types'
import { zines } from '../content/zines'

import { Link } from 'react-router-dom'
import { getPage } from '../Routes'
import { requirements, topics } from '../content/findingOutFuture'
import { awardColours } from '../styles/colors'
import List from '../components/List'

const PageTitle = styled.h1`
  margin: 1em 0;
  font-size: 1.8rem;
  color: ${awardColours.firstPrize};
`

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  color: ${awardColours.firstPrize};
`

function Competition () {
  const zine3 = zines.find(z => z.id === ZineId.ZINE_3)

  return (
    <Page>
      <ContentItem>
        <PageTitle>Finding our Future - Accepting entries!</PageTitle>

        <Paragraph $large>
          It is our ambition to promote new talents and voices in the world of comics and beyond. We are dedicating this International Women’s Day issue to the theme of <strong>Finding our Future</strong>, in recognition of the fact that world events affect women and men differently.
        </Paragraph>

        <Paragraph $large>
          A selection of submissions curated by a committee of comic artists will be published as an issue of our zine.
        </Paragraph>

        <Paragraph $large>
          Also <Link to={getPage('competition2021').path()}>check out the winners</Link> of the 2021 competition, "A Gendered Pandemic".
        </Paragraph>
      </ContentItem>

      <ContentColumns>
        <div>
          <ContentItem>
            <SectionTitle>How To Apply</SectionTitle>

            <Paragraph $large>
              Upload your work to <ExternalLink href="https://wetransfer.com">WeTransfer</ExternalLink> and send a link to the uploaded files to us at info@distantconnections.co.uk. Please check the Submission Rules below for more information.
            </Paragraph>
          </ContentItem>

          <ContentItem>
            <SectionTitle>Submission rules</SectionTitle>

            <Paragraph $large>
              All entries must meet the following requirements to be considered:
            </Paragraph>

            <List>
              {requirements.map(requirement => <li key={requirement}>{requirement}</li>)}
            </List>
          </ContentItem>
        </div>

        <div>
          <ContentItem>
            <SectionTitle>Topics include</SectionTitle>

            <List>
              {topics.map(topic => <li key={topic}>{topic}</li>)}
            </List>

            <Paragraph $large>
              But these are only suggestions. Let your creativity run free!
            </Paragraph>
          </ContentItem>

          <ContentItem>
            <SectionTitle>The Review Comittee</SectionTitle>

            <Paragraph $large>
              The submissions will be reviewed until the end May by a group composed of people from the world of Comics, Arts and Gender research.
            </Paragraph>
          </ContentItem>

          <ContentItem>
            <SectionTitle>Previous competitions</SectionTitle>

            <Paragraph $large>
              You can see the <Link to={getPage('competition2021').path()}>winners and participants</Link> of the 2021 competition, "A Gendered Pandemic", in print in our <ExternalLink href={zine3?.link}>2021 zine</ExternalLink>.
            </Paragraph>
          </ContentItem>
        </div>
      </ContentColumns>
    </Page>
  )
}

export default Competition
