import React, { useState } from 'react'
import styled from 'styled-components'
import { useFormspark } from "@formspark/use-formspark"
import Botpoison from "@botpoison/browser"

import ContentItem from "../components/ContentItem"
import Paragraph from '../components/Paragraph'
import ExternalLink from '../components/ExternalLink'
import {Form, Label, Button, Input, Textarea, Message} from '../components/Forms'

import loaderImage from '../images/loader.svg'

import { instagram, twitter } from '../content/externalLinks'
import Page from '../components/Page'

const {REACT_APP_FORMSPARK_ID, REACT_APP_BOTPOISON_KEY} = process.env

const Title = styled.h1`
  margin: .5em 0;
  font-size: 1.8rem;
`

const FormContainer = styled.div`
  max-width: 35em;
`

if (!REACT_APP_FORMSPARK_ID || !REACT_APP_BOTPOISON_KEY) throw new Error('REACT_APP_FORMSPARK_ID and REACT_APP_BOTPOISON_KEY must be configured for the contact form to work.')

// This uses formspark.io - we have an account, ask Claudia for credentials if you don't have access
const formId = REACT_APP_FORMSPARK_ID

// For spam protection, we use botpoison.com
const botpoison = new Botpoison({
  publicKey: REACT_APP_BOTPOISON_KEY
});

function Contact () {
  const [submit] = useFormspark({
    formId: formId
  });

  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [done, setDone] = useState<boolean>(false)
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setSubmitting(true)
      setError(false)
      const { solution } = await botpoison.challenge();
      await submit({ name, email, message, _botpoison: solution });
      setDone(true)
    } catch(error) {
      setError(true)
    }

    setSubmitting(false)
  };

  return (
    <Page>
      <ContentItem>
        <Title>Contact us</Title>

        <Paragraph>
          Please use the form below to send us a message.
          Alternatively, you can also contact us via <ExternalLink href={twitter}>Twitter</ExternalLink> or <ExternalLink href={instagram}>Instagram</ExternalLink>.
        </Paragraph>

        <FormContainer>
          <Form onSubmit={(e) => onSubmit(e)}>
            <Label>
              <span>Name:</span>
              <Input type="text" value={name} required onChange={(e) => setName(e.target.value)} />
            </Label>

            <Label>
              <span>E-mail:</span>
              <Input type="email" value={email} required onChange={(e) => setEmail(e.target.value)} />
            </Label>

            <Label>
              <span>Message:</span>
              <Textarea required rows={8} value={message} onChange={(e) => setMessage(e.target.value)} />
            </Label>

            <Button type="submit" disabled={submitting}>
              Send {submitting && <img src={loaderImage} alt="Loading" />}
            </Button>
          </Form>

          {error && <Message $type="error">There was an issue when trying to send your form. Please try again later or use one of the contact methods above to reach out to us.</Message>}
          {done && <Message $type="success">Thank you for your message! We will aim to reply as soon as possible.</Message>}
        </FormContainer>
      </ContentItem>
    </Page>
  )
}

export default Contact
