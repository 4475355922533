import {clone} from 'rambda'

export function sortByPropertyName<T>(propertyName: keyof T, array: T[], descending: boolean = false): T[] {
  const clonedArray = clone(array)

  return clonedArray.sort((a: T, b: T) => {
    if (a[propertyName] > b[propertyName]) {
      return descending ? -1 : 1;
    } else if (a[propertyName] < b[propertyName]) {
      return descending ? 1 : -1;
    } else {
      return 0;
    }
  });
}
