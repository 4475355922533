import { PropsWithChildren, ReactNode } from "react";
import ScrollToTopOnMount from "../utils/scrollToTopOnMount";

function Page ({children}: PropsWithChildren<ReactNode>) {
  return (
    <main id="pageContainer">
      <ScrollToTopOnMount />
      {children}
    </main>
  )
}

export default Page
