import styled from 'styled-components'

import ContentItem from "../components/ContentItem"
import Paragraph from '../components/Paragraph'
import ExternalLink from '../components/ExternalLink'
import { instagram, twitter } from '../content/externalLinks'
import Page from '../components/Page'

const Title = styled.h1`
  margin: .5em 0;
  font-size: 1.8rem;
`
function Submit () {
  return (
    <Page>
      <ContentItem>
        <Title>Submit</Title>

        <Paragraph>
          We are not accepting new submissions at the moment.
          Please follow us on <ExternalLink href={twitter}>Twitter</ExternalLink> or <ExternalLink href={instagram}>Instagram</ExternalLink> for news - we will publish a message when submissions open again.
        </Paragraph>
      </ContentItem>
    </Page>
  )
}

export default Submit
