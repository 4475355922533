// Taken from https://gist.github.com/alisterlf/3490957#gistcomment-3407271

const accents: any = 'ÀÁÂÃÄÅĄĀāàáâãäåąßÒÓÔÕÕÖØŐòóôőõöøĎďDŽdžÈÉÊËĘèéêëęðÇçČčĆćÐÌÍÎÏĪìíîïīÙÚÛÜŰùűúûüĽĹŁľĺłÑŇŃňñńŔŕŠŚŞšśşŤťŸÝÿýŽŻŹžżźđĢĞģğ',
	accents_out = "AAAAAAAAaaaaaaaasOOOOOOOOoooooooDdDZdzEEEEEeeeeeeCcCcCcDIIIIIiiiiiUUUUUuuuuuLLLlllNNNnnnRrSSSsssTtYYyyZZZzzzdGGgg",
	accents_map = new Map();

for (const i in accents)
	accents_map.set(accents.charCodeAt(i), accents_out.charCodeAt(Number(i)))

function removeAccents(str: string) {
	const nstr = new Array(str.length);
	let x, i;

	for (i = 0; i < nstr.length; i++)
		nstr[i] = accents_map.get(x = str.charCodeAt(i)) || x;

	return String.fromCharCode.apply(null, nstr);
}

export default function stringToId (name: string) {
  return removeAccents(name).replace(/\s/g, '-')
}
