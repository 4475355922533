import { ReactNode } from 'react'
import styled from 'styled-components'

import {light, primary} from '../styles/colors'

interface Props {
  hasPadding?: boolean
  id?: string
  backgroundColor?: string
  color?: string
  large?: boolean
  children: ReactNode
}

const Wrapper = styled.section<{$hasPadding: boolean, $backgroundColor?: string, $color?: string, $large?: boolean}>`
  color: ${($props) => $props.$color || primary};
  font-size: ${($props) => $props.$large ? '1.5rem' : '1.2rem'};
  position: relative;
  background-color: ${({$backgroundColor}) => $backgroundColor || light};
  padding: ${props => props.$hasPadding ? '1em' : 0};
  margin-bottom: 1em;
`

function ContentItem ({children, id, hasPadding = true, backgroundColor, color, large}: Props) {
  return (
    <Wrapper id={id} $hasPadding={hasPadding} $backgroundColor={backgroundColor} $color={color} $large={large}>
      {children}
    </Wrapper>
  )
}

export default ContentItem
