import {useRef, useState} from 'react'
import {NavLink} from 'react-router-dom'
import styled, {css, keyframes} from 'styled-components'
import Sticky from 'react-stickynode'
import useOnClickOutside from 'use-onclickoutside'

import {emphasis} from '../styles/fonts'
import { light, primary, primaryLight, secondary } from '../styles/colors'
import {navigationSize, layoutMargin} from '../styles/config'
import {highlightedLink, linkStyles} from '../styles/emphasisLinks'
import { fromMedium } from '../styles/mediaQueries'

import {twitter, instagram} from '../content/externalLinks'

import {getPage} from '../Routes'

const stickyShadow = '1px 1px 2px rgba(0,0,0,0.2)'

const menuReveal = keyframes`
  from {
    opacity: 0;
    transform: translateY(-.6em);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const Container = styled.nav`
  width: ${navigationSize};
  flex: 1 1 auto;
  margin-bottom: ${layoutMargin};
  z-index: 200;

  ${fromMedium} {
    flex: 0 0 ${navigationSize};
    margin-right: ${layoutMargin};
  }
`

const SmallScreenNav = styled.div`
  position: relative;

  ${fromMedium} {
    display: none;
  }
`

const LargeScreenNav = styled.div`
  position: relative;
  display: none;

  ${fromMedium} {
    display: block;
  }
`

const MenuToggle = styled.button`
  appearance: none;
  cursor: pointer;
  color: ${primary};
  border: none;
  background: ${light};
  padding: .5em .8em;
  display: flex;
  align-items: center;
  font-family: ${emphasis};
  font-size: .9em;

  > i {
    font-size: 1.2em;
    margin-right: .5em;
  }

  .sticky & {
    margin-top: 1em;
    box-shadow: ${stickyShadow};
  }
`

const ItemsSmallContainer = styled.div`
  background-color: ${primaryLight};
  padding: 1em .8em;
  position: absolute;
  top: 2.6em;
  left: 0;
  box-shadow: ${stickyShadow};
  animation: ${menuReveal} .5s ease;

  .sticky & {
    top: 3.6em;
  }
`

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: .9rem;

  ${fromMedium} {
    .sticky & {
      padding-top: 1em;
    }
  }
`

const Item = styled.li`
  font-family: ${emphasis};
  font-weight: 400;
  margin-bottom: .8em;
`

const Link = styled(NavLink).attrs({
  activeClassName: 'active',
  exact: true
})`
  ${linkStyles}

  &.${props => props.activeClassName} {
    ${highlightedLink(secondary)}
  }
`

const SocialIcons = styled.ul`
  padding: 0;
  margin: .5em 0 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 1rem;
`

const SocialIcon = styled.li`
  margin-right: .8em;
`

const SocialLink = css`
  color: ${primary};
  background-color: ${light};
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5em;
  transition: color .5s ease-in-out;

  &:hover, &:focus {
    color: ${secondary};
  }
`

const SocialLinkExternal = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer'
})`
  ${SocialLink}
`

const SocialLinkInternal = styled(Link)`
  ${SocialLink}
`

function Items () {
  return (
    <>
      <List>
        <Item><Link to={getPage('home').path()}>Home</Link></Item>
        <Item><Link to={getPage('ourZines').path()}>Our Zines</Link></Item>
        <Item><Link to={getPage('whereToBuy').path()}>Where to buy</Link></Item>
        <Item><Link to={getPage('aboutUs').path()}>About us</Link></Item>
        <Item><Link to={getPage('guestArtists').path()}>Guest Artists</Link></Item>
        {/* <Item><Link to={getPage('submit').path()}>Submit</Link></Item> */}
        {/* <Item><Link to={getPage('competition').path()}>Competition - Submit!</Link></Item> */}
        <Item><Link to={getPage('newsAndSocial').path()}>News &amp; Social</Link></Item>
        <Item><Link to={getPage('gallery').path()}>Gallery</Link></Item>
        <Item><Link to={getPage('reviews').path()}>Reviews</Link></Item>
        <Item><Link to={getPage('contact').path()}>Contact</Link></Item>
      </List>

      <SocialIcons>
        <SocialIcon>
          <SocialLinkInternal to={getPage('whereToBuy').path()} title="Shop">
            <i className="fas fa-store" />
          </SocialLinkInternal>
        </SocialIcon>

        <SocialIcon>
          <SocialLinkExternal href={instagram} title="Instagram">
            <i className="fab fa-instagram"></i>
          </SocialLinkExternal>
        </SocialIcon>

        <SocialIcon>
          <SocialLinkExternal href={twitter} title="Twitter">
            <i className="fab fa-twitter"></i>
          </SocialLinkExternal>
        </SocialIcon>
      </SocialIcons>
    </>
  )
}

function SmallScreen () {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const ref = useRef(null)
  
  useOnClickOutside(ref, () => setIsOpen(false))

  return (
    <SmallScreenNav ref={ref}>
      <Sticky bottomBoundary="#pageContainer" activeClass="sticky">
        <MenuToggle type="button" onClick={() => setIsOpen(!isOpen)}>
          <i className="fas fa-bars" /> {isOpen ? 'hide menu' : 'view menu'}
        </MenuToggle>

        {isOpen && (
          <ItemsSmallContainer>
            <Items />
          </ItemsSmallContainer>
        )}
      </Sticky>
    </SmallScreenNav>
  )
}

function LargeScreen () {
  return (
    <LargeScreenNav>
      <Sticky bottomBoundary="#pageContainer" activeClass="sticky">
        <Items />
      </Sticky>
    </LargeScreenNav>
  )
}

function Navigation () {
  return (
    <Container>
      <SmallScreen />
      <LargeScreen />
    </Container>
  )
}

export default Navigation
