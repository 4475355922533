import { ReactNode } from "react"
import styled, {css} from 'styled-components'
import { light, primary, tertiary } from "../styles/colors"
import {emphasis} from '../styles/fonts'

interface Props {
  children: ReactNode
  highlightColor?: string
}

export const highlightSetup = css`
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    background-color: ${tertiary};
    width: calc(.5em + 2px);
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    left: -1px;
  }
`

export const highlight = (color: string) => css`
  padding-left: 1.2em;

  &::before {
    transform: scaleX(1);
    background-color: ${color};
  }
`

const Wrapper = styled.h2<{$highlightColor?: string}>`
  ${highlightSetup}
  ${({$highlightColor}) => $highlightColor ? highlight($highlightColor) : ''}

  position: absolute;
  top: -0.2em;
  left: -0.3em;

  margin: 0;
  font-family: ${emphasis};
  color: ${primary};
  background-color: ${light};
  text-decoration: none;
  display: block;
  padding: .5em .8em;
  transition: padding .5s ease;
  font-size: 1.2em;
`

function DetachedTitle ({children, highlightColor}: Props) {
  return (
    <Wrapper $highlightColor={highlightColor}>
      {children}
    </Wrapper>
  )
}

export default DetachedTitle
