import { createGlobalStyle } from 'styled-components'
import {light, primary, primaryDark, primaryLight, secondary} from './styles/colors'
import { body, emphasis } from './styles/fonts'
import {fromLarge, fromWideScreen, fromVeryLarge} from './styles/mediaQueries'

const gradientColors = (initialPosition: number) => `${light} 0, ${light} ${initialPosition}px, ${primary} ${initialPosition + 1}px, ${primary} ${initialPosition + 220}px, ${primaryLight} ${initialPosition + 221}px, ${primaryLight} ${initialPosition + 540}px, ${secondary} ${initialPosition + 541}px, ${secondary} ${initialPosition + 600}px, ${primaryDark} ${initialPosition + 601}px, ${primaryDark} 100%`

export const AppStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
    background: ${light};
    background: linear-gradient(150deg, ${gradientColors(320)});
  
    min-height: 100vh;
    color: ${primary};
    font-size: 16px;

    // Safe padding for devices with a camera notch: https://webkit.org/blog/7929/designing-websites-for-iphone-x/
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);

    ${fromLarge} {
      background: linear-gradient(155deg, ${gradientColors(430)});
    }

    ${fromWideScreen} {
      background: linear-gradient(160deg, ${gradientColors(600)});
    }

    ${fromVeryLarge} {
      background: linear-gradient(170deg, ${gradientColors(600)});
    }
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    color: ${primary};
  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${body};
    font-weight: 300;
    font-size: 16px;
  }

  code {
    font-family: ${emphasis};
    font-size: 0.8em;
  }

  b {
    font-weight: 400;
  }

  h1, h2, h3, h4 {
    font-family: ${emphasis};
    font-weight: 400;
  }

  h1 b, h2 b, h3 b, h4 b {
    font-weight: 500;
  }

  p a {
    &, &:link {
      color: inherit;
    }

    &:hover {
      color: ${secondary};
    }
  }
`
