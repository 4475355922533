import {ZineId, GuestArtist, ContentParagraphs} from './types'


export const introduction: ContentParagraphs = [{
  text: 'We are honoured to have the contribution of these immensely talented artists in our zines.',
  emphasis: true
}]

export const guestArtists: GuestArtist[] = [{
  name: 'Myfanwy Tristram',
  country: 'England',
  instagram: 'https://instagram.com/myfanwytristram',
  website: 'https://myfanwytristram.com/',
  description: 'Myfanwy is interested in the power of comics to educate, enrage and create change in the world. She led the Draw the line project (100+ comic artists depicting actions anyone can take to change the world) and will shortly be bringing out an extended print edition of her 2019 Longreads essay on fashion, ageing and teenage subcultures, "Cut From the Same Cloth".',
  featuredIn: [ZineId.ZINE_4]
}, {
  name: 'Judy Powell',
  country: 'Ireland',
  instagram: 'https://www.judypowell.online/',
  description: 'Judy studiedfine art at the Winchester School of Art and the University of Wales and has exhibited her work across the United Kingdom and Ireland. Her graphic memoir, "Ways to Kill My Mother\'s Lover (On Limited Pocket Money and with Minimum Mess)", was awarded the LDC Rosalind Penfold Prize in 2021.',
  featuredIn: [ZineId.ZINE_4]
}, {
  name: 'Jane Horton',
  country: 'England',
  instagram: 'https://instagram.com/artyjaneh',
  description: 'Jane is a comic artist and ilustrator. Her artwork has blossomed since having the time in retirement to focus on it. She was shortlisted for her graphic memoir in 2021 by LDC Since then, she has joined the drawntogether collective and is working on several comics for zines.',
  featuredIn: [ZineId.ZINE_4]
}, {
  name: 'Margaret Hartley',
  country: 'United States',
  instagram: 'https://instagram.com/margarethartley_art',
  description: 'Margaret is a Seattle-based artist and teacher. She recently discovered zines and can\'t stop making them. Her cartoons have been published in New Zealand and the USA. Her zine, The Woman Who, can be found in Suzallo Library, University of Washington.',
  featuredIn: [ZineId.ZINE_4]
}, {
  name: 'Lily Williams',
  country: 'England',
  instagram: 'https://www.instagram.com/lwbean/',
  website: 'https://lilywilliamsart.com/',
  description: 'Lily is an artist an cartoonist who is interested in vulnerability and honesty, both in the stories she tells, and the marks she makes. She embraces wobbly lines, mistakes and spontaneity. She\'s fond of bright colours and likes to move fast between the very serious, the very silly and the downright mundane.',
  featuredIn: [ZineId.ZINE_4]
}, {
  name: 'Bill Doan',
  country: 'United States',
  twitter: 'https://twitter.com/DoanWjd1',
  website: 'https://inhaleexhaledraw.com',
  description: 'Visual and Performing Artist making work about living with anxiety and depression.',
  featuredIn: [ZineId.ZINE_2]
}, {
  name: 'Dana Walrath',
  country: 'Armenia and United States',
  website: 'https://danawalrath.com',
  description: 'Dana Walrath, writer, artist, and anthropologist, crosses borders with her work including "Aliceheimer\'s", a graphic memoir and "Like Water on Stone", a verse novel about the Armenian genocide. A Fullbright Scholar and Senior Atlantic Fellow, her comics, essays, and commentary have appeared in The Lancet, Irish Times, Slate, Foreign Policy, on Public Radio.',
  featuredIn: [ZineId.ZINE_2]
}, {
  name: 'Mónica Lalanda',
  country: 'Spain',
  instagram: 'https://www.instagram.com/mlalanda',
  description: 'Mónica Lalanda is a comic artist, doctor, lecturer and writer. Founder of the Spanish Graphic Medicine Movement. Author of the Spanish Medical Ethics Code in a comic format: "Con-Cienca Médica" (LID editorial 2016). Director of the Graphic Medicine Masters Degree (Universidade Internacional de Andalucia).',
  featuredIn: [ZineId.ZINE_2]
}, {
  name: 'Alex Thomas',
  country: 'United States',
  instagram: 'https://www.instagram.com/boostershot',
  description: 'Alex Thomas is a pediatric allergist/immunologist and cartoonist. He is a board member of the Graphic Medicine International Collective and co-founder of Booster Shot Media. Booster Shot Media educates people of all ages about complex health topics using the power of comics.',
  featuredIn: [ZineId.ZINE_2]
}, {
  name: 'Amandine Alessandra',
  country: 'Canada and France',
  instagram: 'https://instagram.com/amandine_alessandra',
  website: 'https://amandinealessandra.com/',
  description: 'Amandine is a photographer and graphic designer born in Réunion Island. After nearly two decades in London, she now lives in Montreal, where she works at providing better visibility to non-male and non-white references in her design teaching.',
  featuredIn: [ZineId.ZINE_3]
}, {
  name: 'Antonia Pia Berger',
  country: 'Chile',
  instagram: 'https://instagram.com/antoniapiaberger',
  website: 'https://antoniaberger.com',
  description: 'Antonia Berger is a designer, illustrator and self proclaimed comic artist. She has some stories to share with you, sometimes awkward stories and sometimes very, very real and emotional ones. Lover of science, plants and hens, all of the three being subjects with which she feels safe.',
  featuredIn: [ZineId.ZINE_3]
}, {
  name: 'Cecil Clemеntine',
  country: 'United Kingdom',
  instagram: 'https://instagram.com/clemochka',
  description: 'Clementine Cecil is an architectural historian and campaigner who spent a lot of time in Russia. She has been Director of SAVE Britain’s Heritage, and Pushkin House, London. She lives in Norfolk and is writing a history of a building in Moscow.',
  featuredIn: [ZineId.ZINE_3]
}, {
  name: 'Rachel Clancy',
  country: 'Ireland',
  instagram: 'https://instagram.com/teacreaturestudios',
  website: 'https://sallyplowman.com',
  description: 'Rachel Clancy is a game designer, art director and mental health advocate. She lives and works with her partner in life and game design Aida and tries not to drive her too crazy with her ADHD and loud chewing.',
  featuredIn: [ZineId.ZINE_3]
}, {
  name: 'MK Czerwiec (aka Comic Nurse)',
  country: 'USA',
  instagram: 'https://instagram.com/mkczerwiec',
  website: 'https://www.comicnurse.com/',
  description: 'MK Czerwiec is a nurse, cartoonist, and educator. She co-manages graphicmedicine.org and lives in Southwest Michigan with her wife and dog.',
  featuredIn: [ZineId.ZINE_3]
}, {
  name: 'Jed Falby',
  country: 'United Kingdom',
  instagram: 'https://instagram.com/jedfalby',
  featuredIn: [ZineId.ZINE_3]
}, {
  name: 'Mary Fleming, Ivana Kaljevic, Yva Mitchell and Alice Parry',
  country: 'United Kingdom',
  description: 'Mary Fleming, Alice Parry, Ivana Kaljevic and Yva Mitchell are doctors who worked at Homerton Hospital in East London during the second wave of the Covid Pandemic. This is their first graphic narrative and offers a glimpse of their experience as clinicians working during that time.',
  featuredIn: [ZineId.ZINE_3]
}, {
  name: 'Fransman Karrie',
  country: 'United Kingdom',
  instagram: 'https://instagram.com/KarrieFransman',
  description: "Karrie Fransman is a comics artist and writer and visual storyteller. She has created comics for The Guardian, The Times, The Arts Council, The Red Cross, The Goethe Insitute, BBC radio and The New Statesman. She's published three books- 'The House That Groaned' (2012) and 'Death of the Artist' (2015) with Penguin Random House and 'Gender Swapped Fairy Tales' with Faber and Faber (2020)",
  featuredIn: [ZineId.ZINE_3]
}, {
  name: 'Jess Hannar',
  country: 'United Kingdom',
  instagram: 'https://instagram.com/bookcoverdrawing',
  website: 'https://jesscomics.bigcartel.com',
  description: ' Jess Hannar lives in London and makes comics with a special interest in physical and mental health. She published her first book of diary comics about life in lockdown in December 2020 and since then her comics have appeared in an exhibition in North London and can be found in the Museum of London library.',
  featuredIn: [ZineId.ZINE_3]
}, {
  name: 'Sarah Lightman',
  country: 'United Kingdom',
  instagram: 'https://instagram.com/thebookofsarahlightman',
  website: 'https://sarahlightman.com',
  description: 'Sarah Lightman is a London-based artist and writer and an Honorary Research Fellow in the School of Arts at Birkbeck College, University of London. She is a faculty member of The Royal Drawing School and was lucky enough to teach the founding members of Distant Connections.',
  featuredIn: [ZineId.ZINE_3]
}, {
  name: 'Sylvia Morgado',
  country: 'Brazil and United Kingdom',
  instagram: 'https://instagram.com/symorgado',
  website: 'https://sylviamorgado.com',
  description: 'Artist and poet with a community and social practice, when not exchanging in the workshops, you’ll probably see her creating participatory artworks! Passionate about the power that art has to connect, promote wellbeing and transform society.',
  featuredIn: [ZineId.ZINE_3]
}, {
  name: 'Alys Scott-Hawkins',
  country: 'United Kingdom',
  instagram: 'https://instagram.com/alysscotthawkins',
  website: 'https://alysscotthawkins.co.uk',
  description: 'Alys is an artist who works with drawing and animation to document lived experience. Her films have won multiple awards at international festivals. This is her first comic, made in great haste. But not, she hopes, her last!',
  featuredIn: [ZineId.ZINE_3]
}, {
  name: 'Nicola Streeten and Sally Plowman',
  country: 'United Kingdom',
  instagram: 'https://instagram.com/s_plowman',
  website: 'http://streetenillustration.com/',
  description: 'Lincolnshire-based mother and daughter artist collaboration. We Are Hungry is a work-in-progress blending graphic memoir with illustrated vegan recipes to show responses to small questions like “what’s for dinner” answer bigger questions like “how can we be happy?”',
  featuredIn: [ZineId.ZINE_3]
}, {
  name: 'Shelley Wall',
  country: 'USA',
  description: 'Shelley Wall is an artist, and an associate professor in the Biomedical Communications graduate program at the University of Toronto.',
  featuredIn: [ZineId.ZINE_3]
}, {
  name: 'Ruth Wiggins and Ezra Waterman-Wiggins',
  country: 'United Kingdom',
  website: 'https://mudpath.wordpress.com/',
  featuredIn: [ZineId.ZINE_3],
  description: 'Ruth lives in London, but is happiest in the great outdoors. She is the author of two poetry pamphlets: Myrtle (Emma Press) and a handful of string (Paekakariki Press); her photographic study of cosplayers, Wonder Women of America, was published by Amos Books.'
}, {
  name: 'Ian Williams',
  country: 'United Kingdom',
  instagram: 'https://instagram.com/thebaddr',
  website: 'http://www.ian-williams.co.uk/',
  description: 'Dr Ian Williams is a comics artist, writer and doctor who has published two graphic novels: The Bad Doctor (2014) and The Lady Doctor (2019).He named the area of study called Graphic Medicine, and is co-author of the Eisner-nominated Graphic Medicine Manifesto.'
}]
