export const onlineShop = 'https://distantconnections.bigcartel.com/products'
export const onlineShopWorld = 'https://www.etsy.com/shop/MikanGeneralStore?section_id=35717577'
export const onlineShopUS = 'https://distantconnections.bigcartel.com/products'
export const twitter = 'https://twitter.com/distant_connect'
export const instagram = 'https://instagram.com/distant_connections_art'

export const goshComics = 'https://goshlondon.com/'
export const goshMap = 'https://goo.gl/maps/KDtLd2jNq9a6ThW89'

export const feministBookshop = 'https://thefeministbookshop.com/'
export const feministBookshopMap = 'https://g.page/thefeministbookshop?share'

export const bookAndFun = 'https://offbeatangles.com/2021/02/08/book-fun-a-haven-for-art-books-in-guangzhou/'
export const bookAndFunMap = 'https://surl.amap.com/2dmIcE6metX'

export const zine3Order = 'https://www.etsy.com/uk/listing/1159932148/the-lockdown-lowdown-zine-women-and'
export const zineFair = 'https://www.hackneycomicfair.com/hall-one'
