import {Artist, ContentParagraphs, ZineId} from './types'

import claudia from '../images/bio/claudia.png'
import federico from '../images/bio/federico.png'
import gaelle from '../images/bio/gaelle.png'
import he from '../images/bio/he.png'
import michael from '../images/bio/michael.png'
import renee from '../images/bio/renee.png'
import sian from '../images/bio/sian.png'
import stella from '../images/bio/stella.png'
import vanessa from '../images/bio/vanessa.png'
import yva from '../images/bio/yva.png'

export const introduction: ContentParagraphs = [{
  text: 'We are a collective of artists, illustrators and writers from U.S, China and Europe. We met during the first 2020 lockdown, in a graphic narratives course taught by Sarah Lightman at the Royal Drawing School.'
}, {
  text: 'We decided to create a participative comic book project to highlight our experiences with lockdown.',
  emphasis: true
}, {
  text: 'We are passionate about narrative, graphic novels and comic books.'
}]

export const artists: Artist[] = [{
  image: claudia,
  name: 'Cláudia Matosa',
  country: 'Portugal and United Kingdom',
  quote: 'My passion is creating stories, via writing, illustration and photography. I mostly make stories about the future, because I love picturing how things can change arounds us while the essence of being human remains the same.',
  twitter: 'https://twitter.com/claudiamatosa',
  instagram: 'https://instagram.com/claudiam.colours',
  featuredIn: [ZineId.ZINE_1, ZineId.ZINE_2, ZineId.ZINE_3, ZineId.ZINE_4]
}, {
  image: federico,
  name: 'Federico Fasce',
  country: 'Italy',
  description: 'Federico is a game designer and an artist from Italy. Once someone has said he’s eclectic like a Pearl Jam album. He likes it. Channeling pure Aquarian energy, Fede is constantly excited about something. Even before morning coffee.',
  instagram: 'https://www.instagram.com/kurai/',
  twitter: 'https://twitter.com/kurai',
  featuredIn: [ZineId.ZINE_1, ZineId.ZINE_2, ZineId.ZINE_3]
}, {
  image: gaelle,
  name: 'Gaëlle Denis',
  country: 'France and United Kingdom',
  description: 'Gaëlle is an award winning French British filmmaker who likes to draw. Gaëlle has studied animation at the Royal College of Art and her work has won many awards including Bafta and at Cannes film festival',
  instagram: 'https://www.instagram.com/gaelledenis_films/',
  featuredIn: [ZineId.ZINE_1, ZineId.ZINE_2, ZineId.ZINE_3, ZineId.ZINE_4]
}, {
  image: he,
  name: 'He Zhu (朱赫)',
  country: 'China',
  description: 'He Zhu an MFA student studying Illustration at the University of Edinburgh. She comes from China. She loves painting, sketching and telling stories through images!',
  instagram: 'https://www.instagram.com/he_zhu_zhu/',
  featuredIn: [ZineId.ZINE_1, ZineId.ZINE_2, ZineId.ZINE_3]
}, {
  image: michael,
  name: 'Michael Green',
  country: 'United States',
  description: 'Michael Green is a physician and artist who lives and rides his bicycle throughout Central Pennsylvania. He is a founding Board Member of the Graphic Medicine International Collective, an organization devoted to the intersection of the medium of comics and the discourse of health care, and is co-author of the Graphic Medicine Manifesto from Penn State University Press. He teaches a course on comics and medicine at Penn State College of Medicine, and has published several landmark articles on the use of comics in medical education.',
  instagram: 'https://www.instagram.com/mjg15/',
  featuredIn: [ZineId.ZINE_1, ZineId.ZINE_2, ZineId.ZINE_3, ZineId.ZINE_4]
}, {
  image: renee,
  name: 'Renée Adams',
  country: 'Germany',
  quote: 'By day, I am an academic. By night I work to improve my drawing skills and think about how to integrate art and science for greater impact. I am dabbling in comic-making to see if comics can help convey scientific ideas that challenge people’s beliefs.',
  twitter: 'https://twitter.com/Renee_B_Adams',
  featuredIn: [ZineId.ZINE_1, ZineId.ZINE_3, ZineId.ZINE_4]
}, {
  image: sian,
  name: 'Siân Leyshon',
  country: 'Wales',
  quote: 'Through my writing and illustration, I am passionate about telling extraordinary stories about ordinary people. ‘A Hug’ was written and drawn for my Grandmother, Mary Likeman, who loved big hugs and penguins.',
  instagram: 'https://www.instagram.com/sianleyshon/',
  twitter: 'https://twitter.com/sianleyshonart',
  featuredIn: [ZineId.ZINE_1, ZineId.ZINE_2]
}, {
  image: stella,
  name: 'Stella Grace',
  country: 'England',
  quote: 'I like to draw. I like to explore. I like to watch ideas develop, twist and turn. I like to learn.',
  instagram: 'https://www.instagram.com/stellagrace_draws/',
  featuredIn: [ZineId.ZINE_1, ZineId.ZINE_2, ZineId.ZINE_3]
}, {
  image: vanessa,
  name: 'Vanessa Lawrence',
  country: 'United Kingdom',
  description: 'Artist & graphic novelist, based in Brighton, UK. 2020 highlights include: The C Word, Comedy Women In Print 2020 long listed, FIDA Worldwide Celine Award nominated, & VIA Arts Prize 2020 Finalist',
  instagram: 'https://www.instagram.com/vanessalawrencexx/',
  featuredIn: [ZineId.ZINE_1, ZineId.ZINE_2, ZineId.ZINE_3, ZineId.ZINE_4]
},{
  image: yva,
  name: 'Yvonne Mitchell',
  country: 'Scotland',
  quote: 'I’ve always dreamed in the genre of science fiction: elements of our world are transported to other places or times, and integrated into new stories. Sometimes, I draw those dreams.',
  instagram: 'https://www.instagram.com/dr.ymitchell/',
  featuredIn: [ZineId.ZINE_1, ZineId.ZINE_3, ZineId.ZINE_4]
}]
