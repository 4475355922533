import {
  Switch,
  Route
} from "react-router-dom";

import {reverse} from 'rambda'

import Home from './pages/Home'
import WhereToBuy from './pages/WhereToBuy'
import Submit from './pages/Submit'
import AboutUs from './pages/AboutUs'
import GuestArtists from './pages/GuestArtists'
import OurZines from './pages/OurZines';
import Competition from './pages/Competition'
import Competition2021 from './pages/Competition2021'
import PressAndSocial from './pages/PressAndSocial'
import Gallery from './pages/Gallery'
import Reviews from './pages/Reviews'
import Contact from './pages/Contact'
import NotFound from './pages/NotFound'

export type PageID = 'home' | 'submit' | 'whereToBuy' | 'aboutUs' | 'guestArtists' | 'ourZines' | 'competition' | 'competition2021' | 'newsAndSocial' | 'gallery' | 'reviews' | 'contact'

type Page = {
  id: PageID,
  path: (params?: {[key: string]: string}) => string,
  title: string
  component: React.FC
}

export const pages: Page[] = [
  {
    id: 'home',
    path: () => '/',
    title: 'Home',
    component: Home
  },
  {
    id: 'whereToBuy',
    path: () => '/where-to-buy',
    title: 'Where to buy',
    component: WhereToBuy
  },
  {
    id: 'submit',
    path: () => '/call-for-comics',
    title: 'Submit',
    component: Submit
  },
  {
    id: 'aboutUs',
    path: () => '/about-us',
    title: 'About us',
    component: AboutUs
  },
  {
    id: 'guestArtists',
    path: () => '/guest-artists',
    title: 'Guest Artists',
    component: GuestArtists
  },
  {
    id: 'ourZines',
    path: () => '/our-zines',
    title: 'Our Zines',
    component: OurZines
  },
  {
    id: 'competition',
    path: () => '/competition',
    title: 'Competition',
    component: Competition
  },
  {
    id: 'competition2021',
    path: () => '/competition/a-gendered-pandemic-2021',
    title: 'Competition Winners 2021',
    component: Competition2021
  },
  {
    id: 'newsAndSocial',
    path: () => '/press-and-social',
    title: 'News & social',
    component: PressAndSocial
  },
  {
    id: 'gallery',
    path: () => '/gallery',
    title: 'Gallery',
    component: Gallery
  },
  {
    id: 'reviews',
    path: () => '/reviews',
    title: 'Reviews',
    component: Reviews
  },
  {
    id: 'contact',
    path: () => '/contact',
    title: 'Contact',
    component: Contact
  }
]

export const getPage = (id: PageID): Page => {
  const page = pages.find(page => page.id === id)
  
  if (!page) {
    throw new Error(`The page ${id} is not declared. Please check Routes.tsx.`)
  }

  return page
}

export function Routes () {
  return (
    <Switch>
      {reverse(pages).map(page => (
        <Route exact key={page.id} path={page.path()} component={page.component} />
      ))}

      <Route path="/*" component={NotFound} />
    </Switch>
  )
}

export default Routes
