import styled from 'styled-components'

import {Artist, GuestArtist} from '../content/types'
import { zines } from '../content/zines'
import Paragraph from './Paragraph'
import Quote from './Quote'
import Emphasis from './Emphasis'

interface Props {
  artist: Artist | GuestArtist
}

const Name = styled.h3`
  margin: .5em 0 0.3em;
`

const Country = styled.div`
  margin: 0 0 0.5em;
  font-weight: 500;
  font-size: 1.1em;
`

const Image = styled.img`
  width: 80px;
  float: left;
  margin: 0 30px 20px 0;
  shape-outside: ellipse(40px 40px at 50% 40%);
`

const ExternalLink = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer'
})`
  color: inherit;
`

function ArtistProfile ({artist}: Props) {
  const {name, twitter, instagram, website} = artist
  const hasLinks = twitter || instagram || website

  return (
    <>
      <Name>{name}</Name>
      {artist.country && <Country>{artist.country}</Country>}
      
      <Paragraph>
        {(artist as Artist).image && <Image src={(artist as Artist).image} alt={name} />}
        {"quote" in artist && <><Quote $type="open" />{artist.quote}<Quote $type="close" /></>}
        {"description" in artist && artist.description}
      </Paragraph>

      {artist.featuredIn && (
        <Paragraph $align="left">
          <Emphasis>
            Featured in: {artist.featuredIn.map((zineId, index, links) => {
              const zine = zines.find(zine => zine.id === zineId)

              return zine ? (
                <>
                  {zine.link ? <ExternalLink href={zine.link}>{zine.title}</ExternalLink> : `${zine.title} (coming soon)`}
                  {index !== links.length - 1 && ', '}
                </>
              ) : null
            })}
          </Emphasis>
        </Paragraph>
      )}

      {hasLinks && (
        <Paragraph $align="right">
          {[['Twitter', twitter], ['Instagram', instagram], ['Website', website]]
            .filter(([_, url]) => !!url)
            .map(([title, url], index, links) => (
              <>
                <ExternalLink href={url}>{title}</ExternalLink>
                {index !== links.length - 1 && ' | '}
              </>
            ))}
        </Paragraph>
      )}
    </>
  )
}

export default ArtistProfile
