import { useMemo } from 'react'
import styled from 'styled-components'

import ContentColumns from '../components/ContentColumns'
import ContentItem from '../components/ContentItem'
import ContentText from '../components/ContentText'
import VisitTheShop from '../components/blocks/VisitTheShop'

import shuffle from '../utils/shuffle'
import stringToId from '../utils/stringToId'

import {introduction, artists} from '../content/aboutUs'
import ArtistProfile from '../components/ArtistProfile'
import { Artist } from '../content/types'
import Page from '../components/Page'

const ArtistList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

const ArtistLink = styled.li`
  width: 5em;
`

const ArtistImage = styled.img`
  width: 100%;
`

function ArtistContent ({artist}: {artist: Artist}) {
  return <ContentItem id={stringToId(artist.name)}><ArtistProfile artist={artist} /></ContentItem>
}

function AboutUs () {
  const list = useMemo<Artist[]>(() => shuffle<Artist>(artists), [])
  const artistsLeft = list.slice(0, list.length / 2 - 1)
  const artistsRight = list.slice(list.length / 2 - 1, list.length)

  return (
    <Page>
      <ContentColumns>
        <div>
          <ContentItem>
            <ContentText content={introduction} />
          </ContentItem>

          <ContentItem>
            <ArtistList>
              {list.map((artist) => (
                <ArtistLink>
                  <a href={`#${stringToId(artist.name)}`}>
                    <ArtistImage src={artist.image} alt={artist.name} title={artist.name} />
                  </a>
                </ArtistLink>
              ))}
            </ArtistList>
          </ContentItem>

          {artistsLeft.map(artist => <ArtistContent artist={artist} />)}
        </div>

        <div>
          {artistsRight.map(artist => <ArtistContent artist={artist} />)}

          <VisitTheShop />
        </div>
      </ContentColumns>
    </Page>
  )
}

export default AboutUs
