import {GuestArtist} from './types'

export const firstPrize: GuestArtist = {
  name: 'R. E. Burke',
  country: 'Wales',
  instagram: 'https://instagram.com/r.e.burke',
  website: 'https://reburke.myportfolio.com/',
  description: 'First prize has been awarded to Rebecca Burke’s playful offering “The Dance”. The jury loved this short, but perfectly formed glimpse of the simultaneous claustrophobia and new liberties of the pandemic -  dealing with a particularly feminine issue! R. E. Burke works full-time in digital publishing but can mostly be found drinking tea and talking about, writing about, or making comics. Previously, she has had comic work published by Dirty Rotten Comics and the Vagabond Comics Collective, and is currently working on a longer comic project about period pain.'
}

export const specialJuryPrize: GuestArtist = {
  name: 'Christine A Humphreys',
  country: 'United Kingdom',
  instagram: 'https://instagram.com/si_ei',
  description: 'A special jury prize has been awarded to Christine A Humphries for her work “Covid Comic #30”: a humorous and relatable tale about re-discovered freedoms, old rules and the art of bicycle riding. Christine studied Architecture in Montreal and completed her professional qualification and a postgraduate diploma in painting in London. In 2020 she was short-listed for the Ladeez Do Comics Prize and long-listed for the Myriad First Graphic Novel Competition.'
}

export const cassArtsAward: GuestArtist = {
  name: 'Mary Fleming, Ivana Kaljevic and Alice Parry',
  country: 'United Kingdom',
  description: 'The Cass Arts Award goes to Ivana Kaljevic, Alice Parry and Mary Fleming for “The Wave”. This was felt to be a strong work from first time artists, capturing alarming realities and surreal memories from working in a hospital during the pandemic. Mary Fleming, Alice Parry, Ivana Kaljevic and Yva Mitchell are doctors who worked at Homerton Hospital in East London during the second wave of the Covid Pandemic. This is their first graphic narrative and offers a glimpse of their experience as clinicians working during that time.'
}

export const specialMention: GuestArtist = {
  name: 'Annum Salman',
  country: 'Pakistan',
  instagram: 'https://instagram.com/writeroholic',
  description: 'A special mention is made of the poem “Stay Safe” by Annum Salman. The jury were moved by its simplicity and power while dealing with the sensitive topic of domestic violence, and the real tension between public health and private safety. Annum Salman is the debut Author of her poetry book, Sense Me, which revolves around the theme of identity, encompassing topics such as gender inequality, mental health, race, culture and love. Born and bred in Pakistan, Annum completed her MA in Creative Writing from the University of Surrey in the UK. Annum is a popular spoken word poet and professionally a creative head at a leading Pakistani PR firm. She delivers poetry workshops across the country and is a speaker at various platforms, most specifically relating to feminism.'
}

export const audienceAward: GuestArtist = {
  name: 'Multiple winners!',
  // country: 'Country',
  instagram: 'https://instagram.com/distant_connections_art',
  description: (
    <>
      <br />
      <p>This special award was based on engagement with our social media channels. The audience has aplauded and decided! Congratulations to all the winners!</p>
      <p><strong>Serap Yilmaz</strong> for Audience Admiration</p>
      <p><strong>Jess Hannar</strong> for Audience Reach</p>
      <p><strong>Federico Fasce</strong> for Engagement &amp; Discussion</p>
      <p><strong>Gaëlle Denis</strong> for Visibility &amp; Engagement</p>
    </>
  )
}

export const participants: GuestArtist[] = [{
  name: 'Cath Appleton',
  country: 'Australia',
  instagram: 'https://instagram.com/cath_drawingaday',
  description: 'I am a visual artist with a particular interest in story telling. I take photographs, create illustrations and make short movies. My stories are told using a single image or unfold in a sequence. Sometimes the image speaks for itself but other times words whisper or shout out meanings, transporting the reader is into other worlds.'
}, {
  name: 'Rachel DL',
  country: 'USA',
  twitter: 'https://twitter.com/wordcalculator',
  website: 'https://www.racheldl.com/',
  description: 'Rachel DL is a disabled artist, writer, and board member for Disability Pride Madison. In the past, she’s published comics in Redivider, Black Warrior Review, and Entropy. She’s currently seeking a publisher for her graphic memoir about being hospitalized during the COVID19 pandemic.'
}, {
  name: 'Lou Doidge',
  country: 'United Kingdom and Germany',
  instagram: 'https://instagram.com/loudoidgedrawing',
  description: 'Louise is a 43 year old woman from London. She kept a visual diary throughout the lockdown, prior to their move to Germany in the summer of 2020. Wife, mother, worrier.'
}, {
  name: 'Ruby Gold',
  country: 'England',
  description: 'Ruby is an English and French student living alone in Pont-sur-Yonne, a tiny village in France. She spent her weekdays assisting teachers in two primary schools and a middle school, trying to communicate with 13 year olds. These drawings come from her commute along the river and evenings in after curfew.'
}, {
  name: 'Margaret F Hartley',
  country: 'USA',
  description: 'Margaret Hartley has cartooned her way through 60 years of life so far as a student, teacher, mother and grandmother.  She is also a painter and writer.'
}, {
  name: 'Laura J Morfett',
  country: 'Northern Ireland',
  description: 'Born and raised in Belfast, Laura still resides there with her family. Until the events of 2020, when homeschooling became a necessity, she was at college studying Art and Design. An avid reader of comics and graphic novels, she has recently begun to write and illustrate her own work.'
}, {
  name: 'Irina Richards',
  country: 'United Kingdom',
  instagram: 'https://instagram.com/irinarichardsart',
  website: 'http://www.irinarichards.com/',
  description: 'Irina is a comics creator and artist educator from Cambridgeshire. Irina positions her practice at the intersection of lived experience and research; her comics investigate human relationships and connections with the world around us.'
}, {
  name: 'Nelly Rosario',
  country: 'USA',
  instagram: 'https://instagram.com/sayansista',
  website: 'https://latino-studies.williams.edu/profile/nar2/',
  description: 'Nelly Rosario is a writer and an associate professor in the Latina/o Studies Program at Williams College.'
}, {
  name: 'Doris Rodríguez',
  country: 'USA',
  instagram: 'https://instagram.com/dorisrodg',
  website: 'https://www.dorisr.com/',
  description: 'Doris Rodríguez is an artist based in New York and Miami.'
}, {
  name: 'Serap Yilmaz',
  country: 'Turkey',
  instagram: 'https://instagram.com/serapyilmaz_illust',
  website: 'http://be.net/serapyilmaz',
  description: 'An artist and instructor from turkey who had MA degree in graphic. Member of the Association of Medical Illustrators, also a PhD student.'
}]
