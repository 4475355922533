import styled, {css} from 'styled-components'

import {
  primary,
  secondary,
  success,
  error
} from '../styles/colors'
import { emphasis } from '../styles/fonts'
import { md, sm, xs } from '../styles/spacing'
import {linkStyles, highlightedLink} from '../styles/emphasisLinks'

const textElement = css`
  width: 100%;
  border: 1px solid ${primary};
  border-radius: 0;
  padding: ${sm}px;
  color: ${primary};
  font-weight: 300;

  &:focus {
    outline: none;
    border-color: ${secondary};
  }
`

export const Form = styled.form`
  padding: ${sm}px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Label = styled.label`
  margin-bottom: ${md}px;
  width: 100%;

  & > span {
    font-family: ${emphasis};
    font-size: .9em;
  }

  & > textarea, & > input {
    margin-top: ${sm}px;
    display: block;
  }
`

export const Button = styled.button`
  ${linkStyles}
  ${highlightedLink(primary)}
  border: none;
  cursor: pointer;
  margin-top: ${sm}px;

  & > img {
    margin-left: ${xs}px;
    height: 1em;
    vertical-align: middle;
  }
`

export const Input = styled.input`
  ${textElement}
`

export const Textarea = styled.textarea`
  ${textElement}
`

export const Message = styled.div<{$type: 'success' | 'error'}>`
  margin-top: 1em;
  color: ${props => props.$type === 'error' ? error : success};
`
