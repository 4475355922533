import { useMemo } from 'react'

import ContentColumns from '../components/ContentColumns'
import ContentItem from '../components/ContentItem'
import ContentText from '../components/ContentText'

import {introduction, guestArtists} from '../content/guestArtists'
import ArtistProfile from '../components/ArtistProfile'
import { GuestArtist } from '../content/types'
import Page from '../components/Page'
import { sortByPropertyName } from '../utils/sortByPropertyName'

function ArtistContent ({artist}: {artist: GuestArtist}) {
  return <ContentItem><ArtistProfile artist={artist} /></ContentItem>
}

function GuestArtists () {
  const list = useMemo<GuestArtist[]>(() => sortByPropertyName<GuestArtist>('name', guestArtists), [])
  const artistsLeft = list.slice(0, list.length / 2)
  const artistsRight = list.slice(list.length / 2, list.length)

  return (
    <Page>
      <ContentColumns>
        <div>
          <ContentItem>
            <h2>Guest Artists</h2>
            <ContentText content={introduction} />
          </ContentItem>

          {artistsLeft.map(artist => <ArtistContent key={artist.name} artist={artist} />)}
        </div>

        <div>
          {artistsRight.map(artist => <ArtistContent key={artist.name} artist={artist} />)}
        </div>
      </ContentColumns>
    </Page>
  )
}

export default GuestArtists
