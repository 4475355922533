import * as React from 'react';
import styled, { css } from 'styled-components';

import {light} from '../styles/colors'
import {emphasis} from '../styles/fonts'
import { breakpointStyles, fromTablet } from '../styles/mediaQueries';
import loaderImage from '../images/loader.svg'
import {md, sm, lg} from '../styles/spacing';

import Modal from '../components/Modal'

export interface OverlayProps {
  next: () => void,
  previous: () => void,
  close: () => void,
  imageUrl: string | null
  imageDescription: string | null
}

const innerPadding = {
  small: '0',
  medium: '0',
  desktop: '2em'
};

const buttonMargin = {
  small: '.5em',
  medium: '1em',
  desktop: '1.5em'
};

const Wrapper = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(30, 30, 30, 0.8);
  background-image: url(${loaderImage});
  background-repeat: no-repeat;
  background-position: center center;

  ${breakpointStyles('padding', innerPadding)}
`;

const ImageElement = styled.img`
  flex: 0 0 auto;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  z-index: 10;
`;

const Description = styled.div`
  color: ${light};
  background-color: rgba(0,0,0,0.8);
  position: absolute;
  z-index: 20;
  left: auto;
  right: auto;
  padding: ${md}px;
  margin: 0 1em;
  bottom: ${sm}px;

  ${fromTablet} {
    bottom: ${lg}px;
  }
`

const ClearButton = css`
  appearance: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  line-height: 1;
  padding: 0;
  z-index: 50;
  cursor: pointer;
  border: none;
  background-color: transparent;
  height: auto;
  width: auto;

  &::before {
    display: block;
    content: '>';
    font-family: ${emphasis};
    font-weight: 600;
    font-size: 50px;
    padding: 0 5px;
    color: white;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }
`;

const Close = styled.button`
  ${ClearButton};
  position: absolute;
  
  ${breakpointStyles('top', buttonMargin)}
  ${breakpointStyles('right', buttonMargin, (value) => `calc(${value} + env(safe-area-inset-right))`)}

  &::before {
    content: 'x';
    font-size: 30px;
  }
`;

const Previous = styled.button`
  ${ClearButton};
  position: absolute;
  top: calc(50vh - 25px);

  ${breakpointStyles('left', buttonMargin, (value) => `calc(${value} + env(safe-area-inset-left))`)}

  &::before {
    content: '<';
  }
`;

const Next = styled.button`
  ${ClearButton};
  position: absolute;
  top: calc(50vh - 25px);

  ${breakpointStyles('right', buttonMargin, (value) => `calc(${value} + env(safe-area-inset-right))`)}

  &::before {
    content: '>';
  }
`;

interface KeyboardActions {
  ArrowLeft: () => void,
  ArrowRight: () => void,
  Escape: () => void
}

const Overlay = ({ next, previous, close, imageUrl, imageDescription }: OverlayProps) => {
  const wrapper = React.useRef(null);
  const [ imageSrc, setImageSrc ] = React.useState<string | null>('');

  React.useEffect(() => {
    // @ts-ignore
    wrapper.current && wrapper.current.focus();
  });

  React.useEffect(() => {
    setImageSrc('');
  }, [imageUrl]);

  React.useEffect(() => {
    setImageSrc(imageUrl)
  }, [imageSrc]) // eslint-disable-line
  
  const keyEvents: KeyboardActions = {
    ArrowLeft: previous,
    ArrowRight: next,
    Escape: close
  };

  const keyUp = ({ key }: React.KeyboardEvent) => {
    const action = (keyEvents as any)[key];
    if (action) action();
  };

  return imageUrl ? (
    <Modal>
      <Wrapper tabIndex={0} onKeyUp={keyUp} ref={wrapper}>
        <Close onClick={close} title="Close" />
        <Previous onClick={previous} title="Previous image" />
        <ImageElement src={imageSrc || ''} alt="" />
        {imageDescription && <Description>{imageDescription}</Description>}
        <Next onClick={next} title="Next image" />
      </Wrapper>
    </Modal>
  ): null
};

export default Overlay;
