import {ContentParagraphs, ContentParagraph} from '../content/types'

import Paragraph from './Paragraph'
import Emphasis from './Emphasis'

interface Props {
  content: ContentParagraphs
}

function ContentText ({content}: Props) {
  return (
    <>
      {content.map((paragraph: ContentParagraph) => (
        <Paragraph>
          {paragraph.emphasis ? <Emphasis>{paragraph.text}</Emphasis> : paragraph.text}
        </Paragraph>
      ))}
    </>
  )
}

export default ContentText
