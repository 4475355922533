import { PropsWithChildren, ReactNode } from "react";
import ReactDOM from 'react-dom';

function Modal ({children}: PropsWithChildren<ReactNode>) {
  const modalContainer = document.getElementById('modal')

  if (!modalContainer) throw new Error('Modal container is not defined')

  return ReactDOM.createPortal(
    children,
    modalContainer
  )
}

export default Modal
