import { ReactNode } from 'react'
import styled, {css} from 'styled-components'
import { fromMedium, fromTablet, fromSmall } from '../styles/mediaQueries'

interface Props {
  children: ReactNode
}

const oneColumn = css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  & > * {
    width: 100%;
  }
`

const twoColumns = css`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    flex: 0 0 48.5%;
  }
`

const Wrapper = styled.div`
  position: relative;
  ${oneColumn};

  ${fromSmall} {
    ${twoColumns}
  }

  ${fromMedium} {
    ${oneColumn}
  }

  ${fromTablet} {
    ${twoColumns}
  }
`

function ContentColumns ({children}: Props) {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

export default ContentColumns
