import { useMemo } from 'react'
import styled from 'styled-components'

import ContentColumns from '../components/ContentColumns'
import ContentItem from '../components/ContentItem'
import ContentText from '../components/ContentText'
import ExternalLink from '../components/ExternalLink'

import shuffle from '../utils/shuffle'

import {foreword, reviews} from '../content/reviews'
import { Review } from '../content/types'
import Paragraph from '../components/Paragraph'
import Page from '../components/Page'

const ForewordTitle = styled.h2`
  margin: .5em 0;
  font-size: 1.8rem;
`

function ReviewContent ({review}: {review: Review}) {
  return (
    <>
      <ContentText content={review.content} />
      <Paragraph>
        <em>{review.author.description} <ExternalLink href={review.author.link}>{review.author.link.replace(/https:\/\//i, '')}</ExternalLink></em>
      </Paragraph>
    </>
  )
}

function AboutUs () {
  const list = useMemo<Review[]>(() => shuffle<Review>(reviews), [])
  const reviewsLeft = list.slice(0, list.length / 2)
  const reviewsRight = list.slice(list.length / 2, list.length)

  return (
    <Page>
      <ContentItem>
        <ForewordTitle>Foreword by Sarah Lightman</ForewordTitle>
        <ReviewContent review={foreword} />
      </ContentItem>

      <ContentColumns>
        <div>
          {reviewsLeft.map(review => <ContentItem><ReviewContent review={review} /></ContentItem>)}
        </div>

        <div>
          {reviewsRight.map(review => <ContentItem><ReviewContent review={review} /></ContentItem>)}
        </div>
      </ContentColumns>
    </Page>
  )
}

export default AboutUs
