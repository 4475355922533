import {css} from 'styled-components'

import {emphasis} from './fonts'
import { light, primary, primaryLight, tertiary } from './colors'

export const highlightedLinkSetup = css`
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    background-color: ${tertiary};
    width: calc(.5em + 2px);
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    left: -1px;
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform .5s ease;
    transition-property: padding, background-color, transform;
  }
`

export const highlightedLink = (color: string) => css`
  padding-left: 1.2em;

  &::before {
    transform: scaleX(1);
    background-color: ${color};
  }
`

export const linkStyles = css`
  ${highlightedLinkSetup}
  font-family: ${emphasis};
  color: ${primary};
  background-color: ${light};
  text-decoration: none;
  position: relative;
  display: block;
  padding: .5em .8em;
  transition: padding .5s ease;

  &:hover {
    ${highlightedLink(primaryLight)}
  }

  &:focus {
    ${highlightedLink(tertiary)}
  }
`
