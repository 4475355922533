import {Link} from 'react-router-dom'

import ContentItem from '../ContentItem'
import ContentLink from '../ContentLink'
import SpreadImage from '../SpreadImage'

import { getPage } from '../../Routes'

function VisitTheShop () {
  const link = getPage('whereToBuy').path()

  return (
    <ContentItem hasPadding={false}>
      <Link to={link}>
        <SpreadImage src="/images/gallery/bookshop3.jpg" />
      </Link>

      <ContentLink to={link} highlighted>
        Where to buy?
      </ContentLink>
    </ContentItem>
  )
}

export default VisitTheShop
