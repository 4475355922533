import {useState, useEffect} from 'react';
import { useAsync } from 'react-async-hook';

import shuffle from './shuffle';

export type Image = {
  width: number,
  height: number,
  thumbnail: string,
  description: string,
  path: string,
  id: number 
}

export type UseGalleryDataResult = {
  loading: boolean,
  error?: Error,
  images: Image[] | null
}

const fetchImageList = async () =>
  (await fetch(`/data/gallery.json`)).json();

const useGalleryData = (): UseGalleryDataResult => {
  const { loading, error, result } = useAsync(fetchImageList, []);
  const [images, setImages] = useState<Image[] | null>(null);

  useEffect(() => {
    if (result) setImages(shuffle<Image>(result));
  }, [result]);

  return { loading, error, images };
};

export default useGalleryData;
