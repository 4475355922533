import styled from 'styled-components'

import ImageGallery from '../components/ImageGallery'
import ContentItem from '../components/ContentItem'
import Paragraph from '../components/Paragraph'
import { onlineShop } from '../content/externalLinks'
import ExternalLink from '../components/ExternalLink'
import Page from '../components/Page'

const PageTitle = styled.h1`
  margin: 0 0 .5em;
  font-size: 1.8rem;
`

function Gallery () {
  return (
    <Page>
      <ContentItem>
        <PageTitle>Gallery</PageTitle>
        <Paragraph>Below you can find pictures of our most recently published zines. They are available for purchase in <ExternalLink href={onlineShop}>our online shop</ExternalLink>.</Paragraph>
        <ImageGallery />
      </ContentItem>
    </Page>
  )
}

export default Gallery
