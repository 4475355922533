import styled from 'styled-components'

import {body} from '../styles/fonts'

const Paragraph = styled.p<{$align?: 'left' | 'right' | 'center', $large?: boolean}>`
  font-family: ${body};
  text-align: ${({$align = 'left'}) => $align};
  font-size: ${({$large = false}) => $large ? '1.2em' : '1em'};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export default Paragraph;
