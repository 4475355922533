import ContentColumns from '../components/ContentColumns'
import ContentItem from '../components/ContentItem'
import Emphasis from '../components/Emphasis'
import Paragraph from '../components/Paragraph'
import Quote from '../components/Quote'
// import InstagramFeed from '../components/InstagramFeed'

import VisitTheShop from '../components/blocks/VisitTheShop'
import ViewTheGallery from '../components/blocks/ViewtheGallery'
// import Zine3 from '../components/blocks/Zine3'
// import TwitterFeed from '../components/TwitterFeed'
import Page from '../components/Page'

function Home () {
  return (
    <Page>
      <ContentColumns>
        <div>
        <ContentItem>
            <Paragraph>
              <Emphasis>
                We are a collective of artists from Europe, China and the United States. 
              </Emphasis>
            </Paragraph>

            <Paragraph>
              We got together to distil the experiences from the remarkable years of 2020 and 2021 into a series of zines.
            </Paragraph>
          </ContentItem>

          {/* <Zine3 /> */}

          <VisitTheShop />
{/* 
          <ContentItem hasPadding={false}>
            <InstagramFeed />
          </ContentItem> */}
        </div>

        <div>
        <ContentItem>
            <Paragraph>
              <Quote $type="open" />
              This zine is living proof that the COVID pandemic had positive sides. I cannot imagine such a diverse group of people coming together to create one zine, let alone two zines, before COVID. Members of our group range from people in arts to people in academia.
            </Paragraph>

            <Paragraph>
              <Emphasis>
                The group welcomed and encouraged all, even beginners like me, and in doing so reminded each and every one of us that there is hope. 
              </Emphasis>
              <Quote $type="close" />
            </Paragraph>

            <Paragraph $align="right">
              <small>
                - <b>Renée Adams</b>, professor at University of Oxford and founding member of Distant Connections
              </small>
            </Paragraph>
          </ContentItem>

          <ViewTheGallery />

          {/* <ContentItem hasPadding={false}>
              <TwitterFeed />
            </ContentItem> */}
        </div>
      </ContentColumns>
    </Page>
  )
}

export default Home
