import type {Review, ContentParagraphs} from './types'

export const introduction: ContentParagraphs = [{
  text: 'We are a collective of artists, illustrators and writers from U.S, China and Europe. We met during the first 2020 lockdown, in a graphic narratives course taught by Sarah Lightman at the Royal Drawing School.'
}, {
  text: 'We decided to create a participative comic book project to highlight our experiences with lockdown.',
  emphasis: true
}, {
  text: 'We are passionate about narrative, graphic novels and comic books.'
}]

export const foreword: Review = {
  content: [{
    text: <>You never forget your first, and the contributors to <em>The Lockdown Lowdown: Graphic Narratives For Viral Times</em> were my first-ever cohort of Graphic Narrative students, from the Royal Drawing School. Between 4 May 2020 and 11 July 2020, I was delighted to teach this talented, funny and engaged group at a time when we were all desperate for connections, during the first lockdown of the year. This was a community, not just a class. We may never have met in person during the 12 weeks, but I was struck by how much time and energy they invested to support each other with lovely verbal comments and with writing thoughtful and appreciative remarks on the class Padlet. So it was no surprise that the community continued long after the course was finished, with their weekly Zoom meetings and the formation of Distant Connections, their collective that spans creators from Europe, China and the United States.</>
  }, {
    text: <>The Lockdown Lowdown is a publication about possibilities within this new, abnormal, world of restrictions. It is also, as Yva Mitchell writes, a rich “harvest” of “stories”, filled with surreal drawing, the thread of time, pathos, transforming penguins, humour, anxious denial, luscious exuberance, varied visual verbal languages, a talking fox, biting snakes, popping eyeballs, wise moons, bright colours, separation, celebration, political consciousness, bra freedom, disillusioned spoke wheels and an indirect hug.</>,
    emphasis: true
  }, {
    text: <>Truly, I shlepp nachas from this publication, and – to borrow from Kamala Harris – whilst this may be Distant Connections’ first publication, I am sure it will not be its last. Congratulations to Gaëlle, Vanessa, He, Michael, Yva, Stella, Renee, Sian, Federico and Claudia.</>
  }],
  author: {
    name: 'Sarah Lightman',
    description: 'Sarah Lightman is a London-based artist, curator, editor, and writer.',
    link: 'https://sarahlightman.com'
  }
}

export const reviews: Review[] = [{
  content: [{
    text: <>All of these comic stories will resonate with readers in these strange times. I was blown away by the beauty and breadth of talent in this collection and the poignancy of the artists' stories. In the past year we have all experienced isolation and panic and been forced to slow down and reflect on what matters most.</>
  }, {
    text: <>These stories are a cathartic read. Each one revealing layers that will resonate with the reader long after the reading.</>,
    emphasis: true
  }, {
    text: <>I hope to see more from these artists in the future!</>
  }],
  author: {
    name: 'Rachael Ball',
    description: 'Rachael Ball is a cartoonist. Her illustrations and cartoons have appeared in various publications.',
    link: 'https://rachaelball.tumblr.com'
  }
}, {
  content: [{
    text: <>Reading The Lockdown Lowdown took me back to the bewildering early days of the lockdown. Its many voices helped me remember and reflect on those experiences and feelings and where I am - and we - are now.</>,
    emphasis: true
  }, {
    text: <>The work is fresh and experimental, full of inventiveness, sadness, fun, and above all, hope - which is always the outcome when talented artists apply themselves to staring the world in the face and showing us how much, despite everything, they still love it.</>
  }],
  author: {
    name: 'Emily Haworth-Booth',
    description: 'Emily Haworth-Booth is an award-winning author, illustrator, and educator who teaches at the Royal Drawing School in London.',
    link: 'https://emilyhaworthbooth.com'
  }
}, {
  content: [{
    text: <>I enjoyed the cultural and stylistic diversity of these entertaining lockdown comics from across the globe.</>
  }, {
    text: <>With warmth and wit, they use the screen-time aesthetic to brilliant effect.</>,
    emphasis: true
  }],
  author: {
    name: 'Ian Williams',
    description: 'Ian Williams is a physician, comics artist and writer, based in Brighton, UK, and author of The Bad Doctor.',
    link: 'https://myriadeditions.com/creator/ian-williams'
  }
}, {
  content: [{
    text: <>This wonderful zine bears vibrant witness to life during the first months of the COVID-19 pandemic.</>
  }, {
    text: <>Through the contractions and expansions of lockdown, the strangeness of distance and closeness, and exploration of the insanity of time, the enduring importance of art as hope shines through.</>,
    emphasis: true
  }],
  author: {
    name: 'MK Czerwiec',
    description: 'MK Czerwiec is a cartoonist, nurse, and educator.',
    link: 'https://comicnurse.com'
  }
}, {
  content: [{
    text: <>Lockdown has been a confusing mess of rising graphs, death, incessant late-night news  scrolling, endless picnics, kisses behind glass, far too many Zoom meetings and far too  much isolation.</>
  }, {
    text: <>This inspired anthology ‘the Lockdown Lowdown’ make sense of this  mess through beautiful images and heartfelt storytelling.</>,
    emphasis: true
  }, {
    text: <>It’s creators, the comic collective  ‘Distant Connections’, manage to do just that- to connect across the world through their  shared experience with each other, but also with us, the readers. Their comics are  heartfelt snap shots that makes the history we’re all living through a little less lonely.</>
  }],
  author: {
    name: 'Karrie Fransman',
    description: 'Karrie Fransman is an award winning comics creator, and author of "Death of The  Artist" and "The House That Groaned".',
    link: 'https://karriefransman.com'
  }
}, {
  content: [{
    text: <>This is a wonderfully rich and varied visual response to the covid pandemic. </>
  }, {
    text: <>The narratives reflect moving and at times humorous interpretations of isolation that readers around the world will recognise.</>,
    emphasis: true
  }],
  author: {
    name: 'Nicola Streeten',
    description: 'Dr. Nicola Streeten, graphic novelist, comics scholar, director of LDComics.',
    link: 'https://streetenillustration.com'
  }
}]
