import styled from 'styled-components'

import ContentColumns from '../components/ContentColumns'
import ContentItem from '../components/ContentItem'
import ExternalLink from '../components/ExternalLink';
// import TwitterFeed from '../components/TwitterFeed';
import Paragraph from '../components/Paragraph';
import Quote from '../components/Quote';
import InstagramFeed from '../components/InstagramFeed';
import ViewTheGallery from '../components/blocks/ViewtheGallery';
import Page from '../components/Page';

const PageTitle = styled.h1`
  margin: 0;
  font-size: 1.8rem;
`

function PressAndSocial () {
  return (
    <Page>
        <ContentColumns>
          <div>
            <ContentItem>
              <PageTitle>Press and social</PageTitle>
            </ContentItem>

            <ContentItem>
              <Paragraph>
                <Quote $type="open" />
                A collective of artists from Europe, China and the United States got together to distill the experiences from the remarkable year of 2020 into a zine. That zine, The Lockdown Lowdown: Graphic Narratives for Viral Times is now available for order.
                <Quote $type="close" />
              </Paragraph>

              <Paragraph $align="right">
                <small>
                  - <b>Comic nurse</b>, from <ExternalLink href="https://www.graphicmedicine.org/lockdown-lowdown/">Graphic Medicine</ExternalLink>
                </small>
              </Paragraph>

              <Paragraph $align="right"><ExternalLink href="https://www.graphicmedicine.org/lockdown-lowdown/">View article</ExternalLink></Paragraph>
            </ContentItem>

            {/* <ContentItem hasPadding={false}>
              <TwitterFeed />
            </ContentItem> */}
          </div>

          <div>
            {/* <ContentItem hasPadding={false}>
              <InstagramFeed />
            </ContentItem> */}

            <ViewTheGallery />
          </div>
        </ContentColumns>
    </Page>
  )
}

export default PressAndSocial
