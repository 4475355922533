import { useMemo } from 'react'
import styled from 'styled-components'

import ContentColumns from '../components/ContentColumns'
import ContentItem from '../components/ContentItem'

import ArtistProfile from '../components/ArtistProfile'
import Page from '../components/Page'
import Paragraph from '../components/Paragraph'
import { sortByPropertyName } from '../utils/sortByPropertyName'
import ExternalLink from '../components/ExternalLink'
import DetachedTitle from '../components/DetachedTitle'
import { awardColours, light } from '../styles/colors'

import {firstPrize, specialJuryPrize, cassArtsAward, specialMention, audienceAward, participants} from '../content/aGenderedPandemic'
import { GuestArtist, ZineId } from '../content/types'
import { zines } from '../content/zines'

import firstPrizeImage from '../images/awards/first-prize.svg'
import specialJuryPrizeImage from '../images/awards/special-jury-prize.svg'
import cassArtsAwardImage from '../images/awards/cassarts-award.svg'
import specialMentionImage from '../images/awards/special-mention.svg'
import audienceAwardImage from '../images/awards/audience-award.svg'
import { fromDesktop } from '../styles/mediaQueries'

const PageTitle = styled.h1`
  margin: 1em 0;
  font-size: 1.8rem;
`

const AwardContent = styled.div`
  padding-top: 2em;
`

const AwardImage = styled.div.attrs({'aria-hidden': true})`
  height: 5em;
  width: auto;
  position: absolute;
  right: 0;
  top: .2em;

  & > img {
    height: 100%;
  }

  ${fromDesktop} {
    height: 6.5em;
  }
`

function ArtistContent ({artist}: {artist: GuestArtist}) {
  return <ContentItem><ArtistProfile artist={artist} /></ContentItem>
}

function Competition () {
  const list = useMemo<GuestArtist[]>(() => sortByPropertyName<GuestArtist>('name', participants), [])
  const artistsLeft = list.slice(0, list.length / 2)
  const artistsRight = list.slice(list.length / 2, list.length)
  const zine3 = zines.find(z => z.id === ZineId.ZINE_3)

  return (
    <Page>
      <ContentItem>
        <PageTitle>A Gendered Pandemic - 2021</PageTitle>

        <Paragraph $large>
          There is extensive evidence that women experienced the COVID-19 pandemic differently than men, in both negative and positive ways. By opening up a special edition of "The Lockdown Lowdown" to external contributions, we seeked to capture the vastness of their experiences. Below you can find a list of artists who have contributed to the project through the competition, as well as the winners of our prizes. 
        </Paragraph>

        <Paragraph $large>
          We would like to extend our warmest congratulations to all winners and participants alike. Thank you for joining us in our journey!
        </Paragraph>

        <Paragraph $large>
          <ExternalLink href={zine3?.link}>
            View "{zine3?.title}"
          </ExternalLink>
        </Paragraph>
      </ContentItem>

      <ContentItem backgroundColor={awardColours.firstPrize} color={light} large>
        <AwardContent>
          <AwardImage>
            <img src={firstPrizeImage} alt="First Prize" />
          </AwardImage>
          <DetachedTitle highlightColor={awardColours.firstPrize}>First Prize</DetachedTitle>
          <ArtistProfile artist={firstPrize} />
        </AwardContent>
      </ContentItem>

      <ContentColumns>
        <ContentItem backgroundColor={awardColours.specialJuryPrize} color={light}>
          <AwardContent>
            <AwardImage>
              <img src={specialJuryPrizeImage} alt="Special Jury Prize" />
            </AwardImage>
            <DetachedTitle highlightColor={awardColours.specialJuryPrize}>Special Jury Prize</DetachedTitle>
            <ArtistProfile artist={specialJuryPrize} />
          </AwardContent>
        </ContentItem>

        <ContentItem backgroundColor={awardColours.cassArtsAward} color={light}>
          <AwardContent>
            <AwardImage>
              <img src={cassArtsAwardImage} alt="Cass Arts Award" />
            </AwardImage>
            <DetachedTitle highlightColor={awardColours.cassArtsAward}>Cass Arts Award</DetachedTitle>
            <ArtistProfile artist={cassArtsAward} />
          </AwardContent>
        </ContentItem>
      </ContentColumns>

      <ContentColumns>
        <ContentItem backgroundColor={awardColours.specialMention} color={light}>
          <AwardContent>
            <AwardImage>
              <img src={specialMentionImage} alt="Special Mention" />
            </AwardImage>
            <DetachedTitle highlightColor={awardColours.specialMention}>Special Mention</DetachedTitle>
            <ArtistProfile artist={specialMention} />
          </AwardContent>
        </ContentItem>

        <ContentItem backgroundColor={awardColours.audienceAward} color={light}>
          <AwardContent>
            <AwardImage>
              <img src={audienceAwardImage} alt="Audience Award" />
            </AwardImage>
            <DetachedTitle highlightColor={awardColours.audienceAward}>Audience Award</DetachedTitle>
            <ArtistProfile artist={audienceAward} />
          </AwardContent>
        </ContentItem>
      </ContentColumns>

      <ContentColumns>
        <div>
          {artistsLeft.map(artist => <ArtistContent key={artist.name} artist={artist} />)}
        </div>

        <div>
          {artistsRight.map(artist => <ArtistContent key={artist.name} artist={artist} />)}
        </div>
      </ContentColumns>
    </Page>
  )
}

export default Competition
