import styled from 'styled-components'
import { Link } from 'react-router-dom'

import ContentItem from "../components/ContentItem"
import Paragraph from '../components/Paragraph'
import Page from '../components/Page'

const Title = styled.h1`
  margin: .5em 0;
  font-size: 1.8rem;
`
function Submit () {
  return (
    <Page>
      <ContentItem>
        <Title>Page not found</Title>

        <Paragraph>
          Sorry, the page you are looking for does not exist! In case it has moved somewhere else, try checking our <Link to="/">homepage</Link> or navigation bar.
        </Paragraph>
      </ContentItem>
    </Page>
  )
}

export default Submit
