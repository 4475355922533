export const topics: Array<string> = [
  'Creativity in times of adversity.',
  'Displacement of women and children.',
  'A war of choice initiated by men.',
  'Women in armed conflict. ',
  'Solidarity.',
  'Threat to Democracy. ',
  'Trauma and tenacity. ',
  'Women’s leadership.',
  'Mental wellbeing. ',
  'Zine making as documentation or/and diary. '
]

export const requirements: Array<string> = [
  'The deadline is May 2nd, 2022.',
  'The entry should be in the form of a comic (1-4 pages), poem (maximum 300 words) or illustration (1-2 pages).',
  'Format for comics and illustrations: CMYK, TIFF, 300 DPI. The pages should be oriented in a portrait position, size A4 (210 x 297 mm) or A3 (297 x 420 mm).',
  'Format for poems: should be submitted in a Microsoft Word document.',
  'Please send a small biography and portrait with your submission (drawing, illustration). If we publish your work, these will go along with it.',
  'Only one application per candidate.',
  'All entries must be original unpublished works.',
  'There is NO entry fee for any submission.',
  'By entering the contest, the author of a winning entry gives permission to Distant Connections to publish their entries both online and in print, as determined by Distant Connections and any future anthologies of winning entries.',
  'You retain the right to publish your entry anywhere else.'
]
