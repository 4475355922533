export const breakpoints = {
  mini: 200,
  small: 650,
  medium: 795, // Navigation bar jumps to top, header images disappear
  tablet: 900,
  desktop: 1024,
  large: 1200,
  wideScreen: 2100,
  veryLarge: 3500
}

export const fromMini = `@media (min-width: ${breakpoints.mini}px)`
export const fromSmall = `@media (min-width: ${breakpoints.small}px)`
export const fromMedium = `@media (min-width: ${breakpoints.medium}px)`
export const fromTablet = `@media (min-width: ${breakpoints.tablet}px)`
export const fromDesktop = `@media (min-width: ${breakpoints.desktop}px)`
export const fromLarge = `@media (min-width: ${breakpoints.large}px)`
export const fromWideScreen = `@media (min-width: ${breakpoints.wideScreen}px)`
export const fromVeryLarge = `@media (min-width: ${breakpoints.veryLarge}px)`

export const breakpointStyles = (
  property: string,
  values: { [string: string]: any },
  valueWrapper?: (value: string) => string
) => Object.keys(values).map((key, index) => {
  if (index === 0) {
    return `
      ${property}: ${values[key]};
    `;
  } else {
    // @ts-ignore
    const value = breakpoints[key]

    return `
      @media (min-width: ${value}px) {
        ${property}: ${valueWrapper ? valueWrapper(values[key]) : values[key]};
      }
    `;
  }
});
