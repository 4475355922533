import styled from 'styled-components'

import {body} from '../styles/fonts'

const List = styled.p<{$align?: 'left' | 'right' | 'center', $large?: boolean}>`
  font-family: ${body};
  text-align: ${({$align = 'left'}) => $align};
  font-size: ${({$large = false}) => $large ? '1.2em' : '1em'};
  padding-left: 0.5em;

  & li {
    list-style-position: inside;
    list-style: disclosure-closed;
    padding: 0.3em 0;
    font-weight: 500;
  }
`

export default List;
